<template>
  <div class="page-container">
    <h1>Oops, page not found</h1>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.page-container {
  display: grid;
  justify-content: center;
  margin-top: 20px;
}
</style>
